@font-face {
  font-family: 'libre';
  src: 
    url(./Fonts/LibreBaskerville-Italic.woff) format('woff'),
}
@font-face {
  font-family: 'inter';
  src: 
    url(./Fonts/Inter-Regular.woff) format('woff'),
}
@font-face {
  font-family: 'libre-regular';
  src: 
    url(./Fonts/LibreBaskerville-Regular.woff) format('woff'),
}
@font-face {
  font-family: 'poppins';
  src: 
    url(./Fonts/Poppins-Regular.woff) format('woff'),
}
.libre-baskerville-italic {
  font-family: 'libre', sans-serif;
}
.libre-regular {
  font-family: 'libre-regular', sans-serif;
}
.poppins {
  font-family: 'poppins', sans-serif;
}
.inter {
  font-family: 'inter', sans-serif;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
